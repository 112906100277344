import './App.scss';
import {IMaskInput} from 'react-imask';
import {useEffect, useRef, useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  const ref = useRef(null);
  const [rate, setRate] = useState('');
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [invalidAccount, setInvalidAccount] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [invalidAmount, setInvalidAmount] = useState(false);
  const [stepText, setStepText] = useState('Начать переводить');
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    amount: 0,
    currency: 'RUR',
    account: '+996',
  });

  useEffect(() => {
    AOS.init();
    getCurrencyRates();
  }, []);

  useEffect(() => {
    setLoading(false);
    setIsError(false);
    const account = data.account.match(/\d/g).join('');
    if(!account?.length || account.length < 12) {
      setInvalidAccount(true);
    } else {
      setInvalidAccount(false);
    }
    if(!data.amount?.length || data.amount < 1) {
      setInvalidAmount(true);
    } else {
      setInvalidAmount(false);
    }
  }, [data]);

  const openCard = () => {
    window.location.href="https://card.kkb.kg";
  };
  const closeIframe = () => {
    document.getElementById("iframeWindow").style.display = 'none';
    setError('');
    setName('');
    setData({
      amount: 0,
      currency: 'RUR',
      account: '+996',
    });
    setInvalidAmount(false);
    setInvalidAccount(false);
    setStepText('Начать переводить');
    setStep(1);
    setSubmitted(false);
  };

  const nextStep = () => {
    if(step === 1) {
      setStepText('Перевести');
      setStep(step + 1);
    } else if(step === 2) {
      setSubmitted(true);
      if(name && !loading) sendTransferRequest(); else if(!invalidAmount && !invalidAccount && !loading) sendCheckRequest();
    }
  };

  const sendCheckRequest = () => {
    setLoading(true);
    setIsError(false);
    fetch('https://admin.kkb.kg/api/transfer/check', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({account: data.account.match(/\d/g).join(''), amount: data.amount}),
    }).then((response) => response.json())
        .then((responseData) => {
          setLoading(false);
          if(responseData.success) {
            setName(responseData.data?.fields?.field1);
            setStepText('Подтвердить перевод');
          } else {
            setIsError(true);
            setError(responseData.errors.comment);
          }
        });
  }
  const sendTransferRequest = () => {
    setLoading(true);
    fetch('https://admin.kkb.kg/api/transfer/create', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({account: data.account.match(/\d/g).join(''), amount: data.amount, currency: data.currency}),
    }).then((response) => response.json())
        .then((responseData) => {
          setLoading(false);
          if(responseData.success) {
            document.getElementById("gfgFrame").src = responseData.data?.url;
            document.getElementById("iframeWindow").style.display = 'flex';
          } else {
            setLoading(false);
            setIsError(true);
            setError(responseData.errors.comment);
          }
        });
  }
  const getCurrencyRates = () => {
    setLoading(true);
    fetch('https://admin.kkb.kg/api/transfer/rate?from=RUB&to=KGS', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
        .then((responseData) => {
          if(responseData.success) {
            setRate(responseData.data.rate);
          } else {
            setIsError(true);
            setError(responseData.errors.comment);
          }
        });
  }

  return (
    <div className="App">
      <div id="iframeWindow" className="transfer_window">
        <iframe id="gfgFrame"
                src=""
                width="100%"
                height="100%"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
        <button className="blue_btn" onClick={() => closeIframe()}>Закрыть</button>
      </div>

      <header>
        <div className="fixed-header">
          <img src="./assets/img/bank-card.png"/>
          <div className="text-fixed">
            <div>Закажи и пользуйся картой Visa Gold уже сегодня!</div>

            <button className="white_btn" onClick={() => {openCard()}}>Оформить карту</button>
          </div>
        </div>
        <section>
          <div className="logo">
            <a className="logo_wrapper" href="/">
              <img src="./assets/img/logo.svg"/>
            </a>
          </div>
        </section>
      </header>
      <main>
        <div className="blured_bg one-background">
          <section id="section_2">
            <h1 className="title">Онлайн-переводы с карты на карту</h1>
            <div className="wrapper-section_2">
              <div className="wrapper-item">
                <img
                    src="./assets/img/transfers-security.svg"
                    className="transfers_description"
                />
                <p>
                  Моментальные и безопасные переводы в рублях на карту ККБ
                </p>
              </div>
            </div>
            <div className="wrapper-section_2-mobile">
              <div className="slide-window">
                <div className="slide-holder" id="slide-holder">
                  <div className="slide">
                    <img
                        src="./assets/img/transfers-security.svg"
                        className="transfers_description"
                    />
                    <p>
                      Моментальные и безопасные переводы в рублях на карту ККБ
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="section_3">
            <form name="form" id="form" className="form">
              <div className="wrapper_section_3 for-mobile">
                <h3 className="title" id="title-sticky">
                  Переводить с KKB выгодно, надежно и быстро!
                </h3>

                {
                  step === 1 && <>
                      <div className="wrapper-section_3" id="infoBoard">
                        <div
                            className="wrapper-item"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                        >
                          <img
                              src="./assets/img/transfer-start.png"
                              className="transfers_description"
                          />
                          <p>Нажмите на кнопку "Начать переводить"</p>
                        </div>
                        <div
                            className="wrapper-item"
                            data-aos="fade-right"
                            data-aos-delay="100"
                            data-aos-duration="1500"
                        >
                          <img
                              src="./assets/img/transfer-sum.png"
                              className="transfers_description"
                          />
                          <p>Укажите сумму перевода</p>
                        </div>
                        <div
                            className="wrapper-item"
                            data-aos="fade-right"
                            data-aos-delay="200"
                            data-aos-duration="2000"
                        >
                          <img
                              src="./assets/img/transfer-card-data.png"
                              className="transfers_description"
                          />
                          <p>Введите номер телефона получателя</p>
                        </div>
                      </div>
                    </>
                }

                {
                  step === 2 && <>
                      {name && !loading && <div className="info-status">
                        Получатель: {name}
                      </div>}
                      {loading && <div className="info-status">
                        Загрузка, подождите.
                      </div>}
                      {isError && <div className="info-status">
                        {error}
                      </div>}
                      <div className="wrapper-section_3" id="inputs">
                        <div
                            className="inputs_item"
                            data-aos="fade-right"
                            data-aos-delay="100"
                            data-aos-duration="1500">
                          <label className="title_input">Номер телефона:</label>
                          <div className="input-wrapper">
                            {!name &&
                              <IMaskInput
                                  className="value"
                                  mask="+{996}(000)000-000"
                                  definitions={{ '#': /[1-9]/ }}
                                  inputRef={ref}
                                  placeholder="+996"
                                  onChange={(e) => {
                                    setData({
                                      ...data,
                                      account: e.target.value
                                    });
                                  }}
                                  overwrite
                              />
                            }
                            {submitted && invalidAccount && <span className="error-span">Введите номер телефона</span>}
                            {name && <input disabled
                                            className="value"
                                            value={data.account}
                            />
                            }
                          </div>
                        </div>
                        <div
                            className="inputs_item"
                            data-aos="fade-right"
                            data-aos-delay="100"
                            data-aos-duration="1500">
                          <label className="title_input"> Сумма:</label>
                          <div className="input-wrapper">
                            <input type="number"
                                   disabled={name?.length}
                                   className="value"
                                   name="amount"
                                   onChange={(e) => {
                                     setData({...data, amount: e.target.value});
                                   }}
                            />
                            {submitted && invalidAmount && <span className="error-span">Введите сумму</span>}
                          </div>
                        </div>

                        <div
                            className="inputs_item"
                            data-aos="fade-right"
                            data-aos-delay="100"
                            data-aos-duration="1500">
                          <label className="title_input"> Валюта: </label>
                          <select disabled className="value" name="currency">
                            <option value="RUR">RUR</option>
                          </select>
                        </div>
                      </div>
                      {rate &&
                        <div className="counted-sum">
                          Сумма в KGS: {data.amount && <span>
                            {parseFloat(data.amount * rate).toFixed(3)} &nbsp;KGS
                          </span>}
                        </div>
                      }
                    </>
                }
                <hr/>
              </div>
              <div className="section_3_transfer_button" id="begin-transfer" onClick={() => nextStep()}>
                <a>
                <span>
                    {stepText}
                </span>

                  <img src="./assets/img/arrow-right.svg" className="arrow"
                  /></a>
              </div>
            </form>
          </section>
          <section id="section_4">
            <div className="wrapper_section_4">
              <div className="wrapper_section_4_items">
                <img src="./assets/img/Vectary texture.png" alt=""/>
              </div>
              <div className="wrapper_section_4_items">
                <h5>Уважаемые клиенты!</h5>
                <p>
                  Будьте внимательны и не сообщайте одноразовые пароли никому, в
                  том числе сотрудникам банка. В целях обеспечения безопасности
                  переводов через интернет и мобильное приложение, списание
                  средств с карты отправителя осуществляется по технологии
                  3DSecure (Verified by Visa/MasterCard Secure Code) в
                  соответствии с правилами, установленными банком, выпустившим
                  вашу карту отправителя.
                </p>
              </div>
            </div>
          </section>
        </div>

        <div className="blured_bg one-background">
          <section id="section_1">
            <div className="offer_wrapper">
              <div className="offer">
                Золотая карта для<br/>
                каждого!
              </div>
              <div className="descriptor">
                Онлайн, бесплатно, с приятными бонусами
              </div>
            </div>
            <div className="cart_image_wrapper">
              <div className="cart_image">
                <img
                    data-aos="rotate-top"
                    src="./assets/img/card_top_2.png"
                    className="card_top"
                />
                <img
                    data-aos="rotate-bottom"
                    src="./assets/img/card_bottom.png"
                    className="card_bottom"
                />
              </div>
            </div>
            <div className="benefits">
              <div
                  className="one_benefit"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1000"
              >
                <div className="one_benefit_icon">
                  <img src="./assets/img/pc.svg"/>
                </div>
                <div className="one_benefit_title">Оформите заявку онлайн</div>
              </div>
              <div
                  className="one_benefit"
                  data-aos="fade-right"
                  data-aos-delay="300"
                  data-aos-duration="1000"
              >
                <div className="one_benefit_icon">
                  <img src="./assets/img/credit-card%201.svg"/>
                </div>
                <div className="one_benefit_title">Получите карту бесплатно</div>
              </div>
              <div
                  className="one_benefit"
                  data-aos="fade-right"
                  data-aos-delay="500"
                  data-aos-duration="1000"
              >
                <div className="one_benefit_icon">
                  <img src="./assets/img/pin.svg"/>
                </div>
                <div className="one_benefit_title">
                  Доставим бесплатно в любую точку Кыргызстана
                </div>
              </div>
            </div>
            <button className="blue_btn" data-aos="fade-up" onClick={() => {openCard()}}>Оформить карту</button>
          </section>
        </div>
      </main>
      <footer>
        <section>
          <div className="menu">
            <div className="logo">
              <img src="./assets/img/white_icon.svg"/>
            </div>
            <div className="row">
              <div className="text">
                При возникновении вопросов, Вы можете обратиться в наш
                круглосуточный колл-центр по номерам:
              </div>
              <div className="number">
                <a href="tel:5000">5000</a>
                <div className="description">(звонок с мобильного бесплатный)</div>
              </div>
              <div className="number">
                <a href="tel:+996312333000">0312 333 000</a>
              </div>
              <div className="number">
                <a href="https://wa.me/+996776333000">0776 333 000</a>
                <div className="description">WhatsApp</div>
              </div>
            </div>
            <div className="row_2">
              <div className="address">
                720021, Кыргызская Республика,<br/>
                г.Бишкек, ул.Шопокова, 101
              </div>
              <a href="https://kkb.kg/" className="link">https://kkb.kg/</a>
              <a href="mailto:info@kkb.kg" className="link">info@kkb.kg</a>
              <div className="social-links">
                <a
                    href="https://www.facebook.com/kyrgyzkommertsbank"
                    target="_blank"
                >
                  <img src="./assets/img/Feature-Icon.svg"/>
                </a>
                <a
                    href="https://www.instagram.com/kyrgyzkommertsbank"
                    target="_blank"
                >
                  <img src="./assets/img/Feature-Icon-1.svg"/>
                </a>
                <a href="https://twitter.com/Kyrgyzkommerts" target="_blank">
                  <img src="./assets/img/Feature-Icon-2.svg"/>
                </a>
                <a
                    href="https://www.youtube.com/channel/UCfDtrcL86DUmiMBtp7Z0elw"
                    target="_blank"
                >
                  <img src="./assets/img/Feature-Icon-3.svg"/>
                </a>
              </div>
            </div>
          </div>
          <div className="rights">
            © Kyrgyzkommertsbank 2021 — Все права защищены.<br/>
            Лицензия НБКР №010, №010/1
          </div>
        </section>
      </footer>
    </div>
  );
}

export default App;
